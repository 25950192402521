/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import {
  faAnglesDown,
  faAsterisk,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IGFLCharacter } from '../../modules/common/model/graphql-types';
import lodash from 'lodash';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Accordion, Button, Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { GFLCharacter } from '../../modules/exilium/components/exilium-character';

interface IZZZCharacterNodes {
  nodes: IGFLCharacter[];
}

interface IZZZCharacterEntry {
  allCharacters: IZZZCharacterNodes;
}

interface IProps {
  data: IZZZCharacterEntry;
}

const ExiliumTierPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Bulwark',
            tooltip: 'Bulwark',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_bulwark.png"
                width={24}
                alt="Bulwark"
              />
            )
          },
          {
            value: 'Sentinel',
            tooltip: 'Sentinel',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_sentinel.png"
                width={24}
                alt="Sentinel"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Vanguard',
            tooltip: 'Vanguard',
            image: (
              <StaticImage
                src="../../images/exilium/icons/class_vanguard.png"
                width={24}
                alt="Vanguard"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            label: 'AR',
            tooltip: 'Assault Rifle',
            value: 'Assault Rifle'
          },
          {
            label: 'HG',
            tooltip: 'Handgun',
            value: 'Handgun'
          },
          {
            label: 'LMG',
            tooltip: 'LMG',
            value: 'LMG'
          },
          {
            label: 'SG',
            tooltip: 'Shotgun',
            value: 'Shotgun'
          },
          {
            label: 'SMG',
            tooltip: 'SMG',
            value: 'SMG'
          },
          {
            label: 'SR',
            tooltip: 'Sniper Rifle',
            value: 'Sniper Rifle'
          },
          {
            label: 'SW',
            tooltip: 'Sword',
            value: 'Sword'
          }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Burn',
            tooltip: 'Burn',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_burn.png"
                width={24}
                alt="Burn"
              />
            )
          },
          {
            value: 'Corrosion',
            tooltip: 'Corrosion',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_corrosion.png"
                width={24}
                alt="Corrosion"
              />
            )
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_electric.png"
                width={24}
                alt="Electric"
              />
            )
          },
          {
            value: 'Freeze',
            tooltip: 'Freeze',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_freeze.png"
                width={24}
                alt="Freeze"
              />
            )
          },
          {
            value: 'Hydro',
            tooltip: 'Hydro',
            image: (
              <StaticImage
                src="../../images/exilium/icons/ele_hydro.png"
                width={24}
                alt="Hydro"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (sortOption === 'cn') {
      setSortOption('cn');
    }

    if (sortOption === 'global') {
      setSortOption('global');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'global', label: 'Global' },
    { value: 'cn', label: 'CN' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page gfl-tier'} game="exilium">
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/exilium/categories/category_tier.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Girls' Frontline 2: Exilium (GFL 2) Tier List</h1>
          <h2>
            Prydwen's tier list for Girls' Frontline 2: Exilium that rates all
            currently available characters.
          </h2>
          <p>
            Last updated: <strong>02/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Tier List" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>About the Tier List</Accordion.Header>
          <Accordion.Body>
            <p>
              <strong className="highlight">
                Girls' Frontline 2: Exilium (GFL 2) is more of a collector/waifu
                game,
              </strong>{' '}
              but when it comes to combat, teams are more important than sole
              characters. A strong character without a proper team to support
              their playstyle/kit will struggle and not perform well. That's why
              when creating our tier list,{' '}
              <strong>
                we didn't look at the character in the vacuum and instead took
                into account their best teams and synergies.
              </strong>{' '}
              Also on their profiles, you will find their pros and cons + an
              overview of their playstyle.
            </p>
            <p>
              The tier list was created with the help of{' '}
              <strong>
                multiple day 1 CN players and other veterans of the CN server
              </strong>{' '}
              who shared their knowledge and experience with us. Special thanks
              to - <strong>Made by RNGesus</strong> and <strong>koj</strong>{' '}
              from our staff and <strong>Ceia, Dr. Brom and Wewechoo</strong>{' '}
              who gave us additional feedback and information.
            </p>
            <h6>Tier Lists</h6>
            <ul>
              <li>
                <strong className="highlight">Global</strong> tier list focuses
                on early/mid game and shows the impact of the character on your
                progression through various PVE modes. Assumes non-optimal
                attachments and only Keys available early. PVP performance isn't
                include in the rating.
              </li>
              <li>
                <strong className="highlight">CN</strong> tier list focuses on
                the late/end game and takes into account the performance of
                characters there based on how well they fit the current meta.
                Assumes optimal attachments builds and best Keys setup. PVP
                performance isn't include in the rating.
              </li>
            </ul>
            <h6>Categories</h6>
            <ul>
              <li>
                <strong className="dps">Damage Dealer</strong> - Characters
                whose main role is to deal damage during the battle and that's
                their primary role,
              </li>
              <li>
                <strong className="amplifier">Amplifiers</strong> - Characters
                who help allies with buffs or hinder enemies with debuffs as
                their primary role,
              </li>
              <li>
                <strong className="healer">Healers</strong> - Characters who
                focus on keeping their allies alive via Heals or Shields, but
                they also can provide buffs (but to a lesser degree than
                Amplifiers),
              </li>
              <li>
                <strong className="tank">Tanks</strong> - Characters who are
                there to take the enemy attention and keep their allies alive.
              </li>
            </ul>
            <p>
              Please keep in mind that some characters could fit multiple
              categories - for example <strong>Suomi</strong> - and in their
              case, we have picked the primary role they fill in combat as their
              default category.
            </p>
            <h6>Special Tag</h6>
            <p>
              Characters with the{' '}
              <FontAwesomeIcon icon={faLink} width="18" className="red" /> tag
              in the top left corner{' '}
              <strong>
                require specific teammates to perform at their full potential
              </strong>
              . If you can't give them who they want, they will perform below
              the rating given to them. We will soon provide a list of all very
              important synergies/pairings.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Criteria</Accordion.Header>
          <Accordion.Body>
            <h6>Criteria</h6>
            <p>Characters are evaluated as such:</p>
            <ul>
              <li>
                For Global tier list, both{' '}
                <strong className="rarity-SR">SR</strong> and{' '}
                <strong className="rarity-SSR">SSR</strong> characters are rated
                at V0 (no dupes),
              </li>
              <li>
                For CN tier list, <strong className="rarity-SSR">SSR</strong>{' '}
                characters are rated at V0 (no dupes) and{' '}
                <strong className="rarity-SR">SR</strong> characters are rated
                at V6 (max dupes),
              </li>
              <li>
                What's taken into consideration: raw power, versatility (teams
                and builds), utility (how unique or essential their kits are),
                how hard the characters are to play to their full potential,
              </li>
              <li>
                Characters are compared only within the same category when it
                comes to their placement on the tier list. So don't try to
                compare DPS with Sustains based on their ratings,
              </li>
              <li>
                <strong>Top Picks:</strong>
              </li>
              <ul>
                <li>
                  <strong className="tier-s-plus">S+ Tier</strong>: The absolute
                  best at their category, while also offering extra benefits.
                </li>
                <li>
                  <strong className="tier-s">S Tier</strong>: On par with the
                  characters from S+ Tier, but lack the extra benefits that make
                  the S+ Tier unique.
                </li>
              </ul>
              <li>
                <strong>Good Picks:</strong>
              </li>
              <ul>
                <li>
                  <strong className="tier-a">A Tier</strong>: Compared to S tier
                  characters, they have some sort of drawback - such as needing
                  extra investment or a specific environment/characters to be
                  paired with to work well.
                </li>
                <li>
                  <strong className="tier-b">B Tier</strong>: Not bad, but
                  definitely need investment or specific circumstances to shine
                  and reach the same level of A Tier or above.
                </li>
              </ul>
              <li>
                <strong>Niche Picks:</strong>
              </li>
              <ul>
                <li>
                  <strong className="tier-c">C Tier</strong>: Characters who
                  work on very specific (niche) circumstances or who rarely play
                  their role, functioning as a placeholder or substitute for
                  characters who are from tiers above.
                </li>
                <li>
                  <strong className="tier-d">D Tier</strong>: Underwhelming
                  characters that have fully fallen out of the meta for various
                  reasons and it's hard to make them viable.
                </li>
              </ul>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Changelog</Accordion.Header>
          <Accordion.Body>
            <h6>02.12.2024</h6>
            <p>Official release of the Global and CN tier lists.</p>
            <h6>28.11.2024</h6>
            <p>
              Initial release of the tier list page. Ratings are still in
              progress.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="banner gfl-promo">
        <h4>Play & Reroll GFL 2 on PC</h4>
        <OutboundLink href="https://bstk.me/o485XScik" target="_blank">
          <Button variant="primary">Reroll now on BlueStacks</Button>
        </OutboundLink>
      </div>
      <div className="tier-list-header">
        <p className="title">
          Tier List{' '}
          <span>
            ({sortOption === 'global' && 'Global - Early/Mid game'}
            {sortOption === 'cn' && 'CN - Late/End game'})
          </span>
        </p>
        <p className="sub-title">
          You're currently viewing the{' '}
          <strong>
            {sortOption === 'global' && 'Global (Early/Mid game)'}
            {sortOption === 'cn' && 'CN (Late/End game)'}
          </strong>{' '}
          tier list. It shows{' '}
          {sortOption === 'global' &&
            'how the character performs within their role in all PVE content available in the Global server with a bigger focus on early/mid game progression.'}
          {sortOption === 'cn' && (
            <>
              how the character performs within their role in all PVE content
              available in the CN server with a focus on late/end game meta.
            </>
          )}{' '}
          Use the switcher below to view a different tier list.
        </p>
      </div>
      <div className="tier-list-switcher">
        <div
          className={`option global ${sortOption === 'global' && 'selected'}`}
          onClick={() => setSortOption('global')}
        >
          <p>Global (Early/Mid game)</p>
        </div>
        <div
          className={`option cn ${sortOption === 'cn' && 'selected'}`}
          onClick={() => setSortOption('cn')}
        >
          <p>CN (Late/End game)</p>
        </div>
      </div>
      <div className="employees-filter-bar gfl">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="custom-tier-list-gfl">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Damage dealer</div>
            <div className="custom-header amplifier">Amplifier</div>
            <div className="custom-header healer">Sustain</div>
            <div className="custom-header tank">Tank</div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header top">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Top Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <>
          <div className="custom-tier tier-s-plus first">
            <div className="tier-rating s-plus">
              <span>S+</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Damage dealer</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Amplifier</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Sustain</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'Healer')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile tank">Tank</div>
              <div className="employees-container tank">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'Tank')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-s ">
            <div className="tier-rating s">
              <span>S</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Damage dealer</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Amplifier</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Sustain</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'Healer')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile tank">Tank</div>
              <div className="employees-container tank">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'Tank')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={`meta-line`}>
            <h5 className="meta-header alt">
              <FontAwesomeIcon icon={faAnglesDown} width="18" /> Good Picks{' '}
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-a ">
            <div className="tier-rating a">
              <span>A</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Damage dealer</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Amplifier</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Sustain</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'Healer')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile tank">Tank</div>
              <div className="employees-container tank">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'Tank')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-b ">
            <div className="tier-rating b">
              <span>B</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Damage dealer</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Amplifier</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Sustain</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'Healer')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile tank">Tank</div>
              <div className="employees-container tank">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'Tank')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={`meta-line`}>
            <h5 className="meta-header niche">
              <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche Picks{' '}
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-c ">
            <div className="tier-rating c">
              <span>C</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Damage dealer</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Amplifier</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Sustain</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'Healer')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile tank">Tank</div>
              <div className="employees-container tank">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'Tank')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-d ">
            <div className="tier-rating d">
              <span>D</span>
            </div>
            <div className="custom-tier-container">
              <div className="category-mobile dps">Damage dealer</div>
              <div className="employees-container dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile amplifier">Amplifier</div>
              <div className="employees-container amplifier">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile healer">Sustain</div>
              <div className="employees-container healer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'Healer')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="category-mobile tank">Tank</div>
              <div className="employees-container tank">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'Tank')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <GFLCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                            showDupes
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ExiliumTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List (GFL 2) | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="Prydwen's tier list for Girls' Frontline 2: Exilium (GFL 2) that rates all currently available characters."
    game="exilium"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulGflCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        element
        weapon
        weaponName
        ratings {
          cn
          global
        }
        tierCategory
      }
    }
  }
`;
